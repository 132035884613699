import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

function SolutionSp() {
    const [active, setActive] = useState(null);
    const solRef = useRef();

    const targetRef1 = useRef(null);
    const targetRef2 = useRef(null);
    const targetRef3 = useRef(null);

    const videoRef1 = useRef();
    const videoRef2 = useRef();
    const videoRef3 = useRef();

    useIntersectionObserver(targetRef1, videoRef1);
    useIntersectionObserver(targetRef2, videoRef2);
    useIntersectionObserver(targetRef3, videoRef3);

    const streamingVideoUrl1 = process.env.REACT_APP_MOVIES_SOLUTION1_SP;
    const streamingVideoUrl2 = process.env.REACT_APP_MOVIES_SOLUTION2_SP;
    const streamingVideoUrl3 = process.env.REACT_APP_MOVIES_SOLUTION3_SP;

    useEffect(() => {
        // Set the video source when it's available
        if (videoRef1.current) {
            videoRef1.current.src = streamingVideoUrl1;
        }
        if (videoRef2.current) {
            videoRef2.current.src = streamingVideoUrl2;
        }
        if (videoRef3.current) {
            videoRef3.current.src = streamingVideoUrl3;
        }
    }, [streamingVideoUrl1, streamingVideoUrl2, streamingVideoUrl3]);

    useEffect(() => {
        let ctx;
        const initGsap = () => {
            ctx && ctx.revert();
            ctx = gsap.context(() => {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: solRef.current,
                        start: 'top top',
                        end: '+=200',
                        invalidateOnRefresh: true,
                        pin: true,
                    },
                });
            }, solRef);
        };

        initGsap(); // 初回の gsap 初期化

        window.addEventListener('load', initGsap); // ページの読み込みが完了したら実行

        return () => {
            // クリーンアップ処理
            window.removeEventListener('load', initGsap);
            ctx && ctx.revert();
        };
    }, []);

    const classToggle = (containerId) => {
        setActive((prevActive) => (prevActive === containerId ? null : containerId));

        // スクロール対象の要素を取得
        const targetElement = document.getElementById('solution');

        // targetElementが存在する場合にスクロール
        if (targetElement) {
            targetElement.scrollIntoView({
                block: 'start', // 要素の先頭を基準にスクロール
            });
        }

        // activeがある場合はスクロール禁止
        if (active) {
            document.body.style.overflow = 'scroll';
        } else {
            document.body.style.overflow = 'hidden';
        }
    };

    const getContainerStyle = (containerId) => ({
        display: active === containerId || active === null ? 'block' : 'none',
    });

    const getContainerClassName = (containerId) => `solution-container ${active === containerId ? 'solution-container-open' : ''}`;

    return (
        <div className={active ? 'solution-open' : 'solution'} ref={solRef} id="solution">
            <h1 className="title">Solution</h1>
            <div className="solution-contents">
                <div
                    id="solution-video1"
                    ref={targetRef1}
                    onClick={() => classToggle('solution-video1')}
                    style={{ ...getContainerStyle('solution-video1') }}
                    className={getContainerClassName('solution-video1')}
                >
                    <video ref={videoRef1} autoPlay loop muted playsInline loading="lazy"></video>
                    <div className="solution-container-wrap">
                        <div className="solution-container-h2-wrap">
                            <div className="solution-container-h2">01</div>
                            <div className="solution-container-h2-line"></div>
                            <div className="solution-container-title">プログラミングスクール</div>
                        </div>
                        <div id="solution-containerTextWrap" className={active === 'solution-video1' ? 'solution-container-text-wrap-open' : 'solution-container-text-wrap'}>
                            <div className="solution-container-text">
                                私たちはIT人材の育成に力を入れています。子供向けのプログラミングスクールから大人向けのプログラミングスクールまで
                                展開しており、IT人材不足がささやかれる日本で優秀なIT人材を増やすために注力しております。子供向けプログラミングスクールでは
                                代表が子供のころから独学でプログラミングを学んでいた経験を活かし運営しております。大人向けプログラミングスクールでは
                                実際に現役フリーランスとして活動するエンジニアや元大企業のエンジニア中心に実践的なカリキュラムで運営しております。{' '}
                            </div>
                            <div className="solution-container-text-link-wrap">
                                <div className="solution-container-text-link">
                                    <a href="https://school.K-Platinum.com/k-programming/">
                                        <div className="solution-container-text-link-title">Kプログラミングスクール</div>
                                        <div className="solution-container-text-link-img">
                                            <img src="img/school1.png"  alt=""/>
                                        </div>
                                    </a>
                                </div>
                                <div className="solution-container-text-link">
                                    <a href="https://school.K-Platinum.com/k-freelance/">
                                        <div className="solution-container-text-link-title">Kfree</div>
                                        <div className="solution-container-text-link-img">
                                            <img src="img/school2.png" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="solution-video2"
                    ref={targetRef2}
                    onClick={() => classToggle('solution-video2')}
                    style={{ ...getContainerStyle('solution-video2') }}
                    className={getContainerClassName('solution-video2')}
                >
                    <video ref={videoRef2} autoPlay loop muted playsInline loading="lazy"></video>
                    <div className="solution-container-wrap">
                        <div className={active === 'solution-video2' ? 'solution-container-h2-wrap-open' : 'solution-container-h2-wrap'}>
                            <div className={active === 'solution-video2' ? 'solution-container-h2-open' : 'solution-container-h2'}>02</div>
                            <div className={active === 'solution-video2' ? 'solution-container-h2-line-open' : 'solution-container-h2-line'}></div>
                            <div className={active === 'solution-video2' ? 'solution-container-title-open' : 'solution-container-title'}>ITコンサルティング</div>
                        </div>
                        <div id="solution-containerTextWrap" className={active === 'solution-video2' ? 'solution-container-text-wrap-open' : 'solution-container-text-wrap'}>
                            <div className="solution-container-text">
                                私たちはITコンサル事業を展開しております。外資系の大手金融会社のクライアントを中心に、様々な企業に対してITコンサルティングを提供しております。
                                上流から下流までのトータルコーディネートが可能で、要件定義だけでなく、開発やマーケティング、UI/UX設計なども担うことが出来る高いITスキルを有しております。
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="solution-video3"
                    ref={targetRef3}
                    onClick={() => classToggle('solution-video3')}
                    style={{ ...getContainerStyle('solution-video3') }}
                    className={getContainerClassName('solution-video3')}
                >
                    <video ref={videoRef3} autoPlay loop muted playsInline loading="lazy"></video>
                    <div className="solution-container-wrap">
                        <div className="solution-container-h2-wrap">
                            <div className="solution-container-h2">03</div>
                            <div className="solution-container-h2-line"></div>
                            <div className="solution-container-title">独立支援事業部</div>
                        </div>
                        <div id="solution-containerTextWrap" className={active === 'solution-video3' ? 'solution-container-text-wrap-open' : 'solution-container-text-wrap'}>
                            <div className="solution-container-text">
                                弊社は若いエンジニアの独立を支援しております。独立のためのノウハウの伝授やコーチング、事業計画書の書き方や案件の獲得方法についてアドバイスをしています。
                                案件獲得後の立ち回りなども徹底的にサポートします。エンジニア独立のためのカリキュラムを用意しております。
                                また、社会人エンジニアに対しても社内での評価を上げるためのコーチングをしております。社会人として他より優秀だと思えないと独立する気にはならないです。
                                私たちは自身のスキルをアップさせたい方のお手伝いをしたいと考えています。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SolutionSp;
