import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export const PolicySp = () => {
    const polRef = useRef();

    let ctx;
    useEffect(() => {
        ctx && ctx.revert();
        ctx = gsap.context(() => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: polRef.current,
                    start: 'top top',
                    end: '+=200', // アニメーションが終了した後、さらに200pxスクロールされたらスクロールを再開
                    invalidateOnRefresh: true,
                    pin: true,
                },
            });
        }, polRef);
    }, []);
    return (
        <div className="policy" ref={polRef} id="policy">
            <div>
                <h1 className="policy-title title">Policy</h1>
            </div>
            <div className="policy-container">
                <div className="policy-container-title">
                    <div className="policy-container-title-K blinking-glow">K</div>
                </div>
                <ul className="cube">
                    <li className="policy-top"></li>
                    <li className="policy-front">
                        <div className="policy-text-box">
                            <div className="policy-text">
                                <h1>KEWL</h1>
                                カッコイイ、クール(スラング)
                                <br />
                                私たちは常にカッコよくありたい。どんな時でも、遊び心を忘れず、スラングを生み出していくような若者心を持ち、日々revolutionしています。
                            </div>
                        </div>
                    </li>
                    <li className="policy-right">
                        <div className="policy-text-box">
                            <div className="policy-text">
                                <h1>KITE</h1>
                                高く舞う、舞い上がる。
                                <br />
                                私たちは高度情報化社会の中、凧のように高く舞い上がるということを目標に、毎日にinnovationを起こしています。
                            </div>
                        </div>
                    </li>
                    <li className="policy-back">
                        <div className="policy-text-box">
                            <div className="policy-text">
                                <h1>KUDOS</h1>
                                称賛、賛辞、栄光、名声
                                <br />
                                皆に称賛され、喜ばれたい。そして、栄光を掴み社会に大きな貢献をもたらしたい。そのマインドを持ち続けながらworkしています。
                            </div>
                        </div>
                    </li>
                    <li className="policy-left">
                        <div className="policy-text-box">
                            <div className="policy-text policy-text-left">
                                <h1>These are our policy.</h1>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
