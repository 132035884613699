import React, { useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { gsap } from 'gsap';

export const Recruit = () => {
    const conRef = useRef();

    let ctx;
    useEffect(() => {
        ctx && ctx.revert();
        ctx = gsap.context(() => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: conRef.current,
                    start: 'top top',
                    end: '+=200', // アニメーションが終了した後、さらに200pxスクロールされたらスクロールを再開
                    invalidateOnRefresh: true,
                    pin: true,
                },
            });
        }, conRef);
        const streamingVideoUrl = process.env.REACT_APP_MOVIES_RECRUIT;
        // video要素を取得してストリーミングURLを設定
        const videoElement = document.getElementById('recruitvideo');
        videoElement.src = streamingVideoUrl;
    }, []);
    /*                <HashLink to="/Benefits/#Benefits" className="button-recruit">
                    福利厚生
                </HashLink>
                */
    return (
        <div class="recruit" ref={conRef} id="recruit">
            <div class="text">
                <div class="head1">Accelerate</div>
                <div class="head2">
                    <div class="head2-1">your</div>
                    <div class="head2-2">thinking</div>
                </div>
                <p>
                    私たちと一緒に働いてくれる
                    <wbr />
                    メンバーを募集しております。
                    <wbr />
                    やる気がある、日本を変えたい、
                    <wbr />
                    楽しく働きたいというメンバーを
                    <wbr />
                    募集しています。
                    <wbr />
                    詳細は下記リンクをクリック
                    <wbr />
                    してください
                </p>
                <HashLink to="/Recruit/#Recruit" className="button-recruit">
                    採用ページはこちら
                </HashLink>
            </div>
            <div class="filter"></div>
            <video id="recruitvideo" autoPlay loop muted playsInline loading="lazy"></video>
        </div>
    );
};
