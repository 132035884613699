import React, { useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { isMobile } from 'react-device-detect';

export const Sidebar_NotHome = () => {
    const imageUrl = isMobile
        ? process.env.REACT_APP_MOVIES_LOGO_SP
        : process.env.REACT_APP_MOVIES_LOGO;
    const logo = {
        backgroundImage: `url(${imageUrl})`,
    };
    return (
        <aside class="menu">
            <nav class="global-nav">
                <ul>
                    <li>
                        <HashLink to="/">
                            <div class="logo">
                                <h1 style={logo}>K</h1>
                            </div>
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to="/" id="side-top">
                            TOP
                        </HashLink>
                    </li>

                    <li>
                        <HashLink to="/#policy" id="side-policy">
                            Policy
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to="/#solution" offset="-1" id="side-sol">
                            Solution
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to="/#member" id="side-member">
                            Member
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to="/#recruit" id="side-recruit">
                            Recruit
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to="/#contact" id="side-contact">
                            Contact
                        </HashLink>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};
