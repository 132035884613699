import { mediaQuery, useMediaQuery } from './components/UseMediaQuery';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { SpComponent_home } from './SpComponent_home';
import { SpComponent_CompanyInfo } from './SpComponent_CompanyInfo';
import { SpComponent_Benefits } from './SpComponent_Benefits';
import { SpComponent_PrivacyPolicy } from './SpComponent_PrivacyPolicy';
import { SpComponent_RecruitDetail } from './SpComponent_RecruitDetail';
import { SpComponent_MemberDetail } from './SpComponent_MemberDetail';
import { PcComponent_home } from './PcComponent_home';
import { PcComponent_CompanyInfo } from './PcComponent_CompanyInfo';
import { PcComponent_Benefits } from './PcComponent_Benefits';
import { PcComponent_PrivacyPolicy } from './PcComponent_PrivacyPolicy';
import { PcComponent_RecruitDetail } from './PcComponent_RecruitDetail';
import { PcComponent_MemberDetail } from './PcComponent_MemberDetail';

function App() {
    const isSp = useMediaQuery(mediaQuery.sp);

    if (isSp) {
        return (
            <HashRouter>
                <Routes>
                    <Route path="/" element={<SpComponent_home />} />
                    <Route path="/CompanyInfo" element={<SpComponent_CompanyInfo />} />
                    <Route path="/Benefits" element={<SpComponent_Benefits />} />
                    <Route path="/PrivacyPolicy" element={<SpComponent_PrivacyPolicy />} />
                    <Route path="/Recruit" element={<SpComponent_RecruitDetail />} />
                    <Route path="/Member" element={<SpComponent_MemberDetail />} />
                </Routes>
            </HashRouter>
        );
    }
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<PcComponent_home />} />
                <Route path="/CompanyInfo" element={<PcComponent_CompanyInfo />} />
                <Route path="/Benefits" element={<PcComponent_Benefits />} />
                <Route path="/PrivacyPolicy" element={<PcComponent_PrivacyPolicy />} />
                <Route path="/Recruit" element={<PcComponent_RecruitDetail />} />
                <Route path="/Member" element={<PcComponent_MemberDetail />} />
            </Routes>
        </HashRouter>
    );
}

export default App;
