import React, { useRef } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { isMobile } from 'react-device-detect';

export const Sidebar = () => {
    const policyRef = useRef();

    const scrollToPolicy = () => {
        policyRef?.current?.scrollIntoView();
    };

    const imageUrl = isMobile
        ? process.env.REACT_APP_MOVIES_LOGO_SP
        : process.env.REACT_APP_MOVIES_LOGO;
    const logo = {
        backgroundImage: `url(${imageUrl})`,
    };
    return (
        <aside class="menu">
            <nav class="global-nav">
                <ul>
                    <li>
                        <a href="#">
                            <div class="logo">
                                <h1 style={logo}>K</h1>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" id="side-top">
                            TOP
                        </a>
                    </li>

                    <li>
                        <AnchorLink href="#policy" id="side-policy">
                            Policy
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#solution" offset="-1" id="side-sol">
                            Solution
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#member" id="side-member">
                            Member
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#recruit" id="side-recruit">
                            Recruit
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#contact" id="side-contact">
                            Contact
                        </AnchorLink>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};
