import React, { useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useIntersectionObserver from '../hooks/useIntersectionObserver';


export const BackVideo = () => {
    const targetRef = useRef(null);
    const videoRef = useRef(null);
    useIntersectionObserver(targetRef, videoRef);

    const desktopVideoUrl = process.env.REACT_APP_MOVIES_BACK;
    const mobileVideoUrl = process.env.REACT_APP_MOVIES_BACK_SP;

    const videoSource = isMobile ? mobileVideoUrl : desktopVideoUrl;

    useEffect(() => {
        // Set the video source when it's available
        if (videoRef.current) {
            videoRef.current.src = videoSource;
        }
    }, [videoSource]);

    return (
        <div
            ref={targetRef}
            className="BackVideo"
        >
            <video
                ref={videoRef}
                id="backvideo"
                autoPlay
                loop
                muted
                playsInline
                loading="lazy"
            />
        </div>
    );
};
