import React, { useEffect, useRef, useState } from 'react';
import { gsap, scrollTrigger } from 'gsap';

export const Solution = () => {
    const [activeSol1, setActiveSol1] = useState(true);
    const [activeSol2, setActiveSol2] = useState(true);
    const [activeSol3, setActiveSol3] = useState(true);
    const solRef = useRef();

    let ctx;
    useEffect(() => {
        ctx && ctx.revert();
        ctx = gsap.context(() => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: solRef.current,
                    start: 'top top',
                    end: '+=200', // アニメーションが終了した後、さらに200pxスクロールされたらスクロールを再開
                    invalidateOnRefresh: true,
                    pin: true,
                },
            });
            tl.set('.sol1,.sol3', { y: -50 });
            tl.set('.sol2', { y: 50 });
            tl.to('.sol', {
                y: 0,
                opacity: 1,
                stagger: {
                    each: 0.2,
                },
            });
        }, solRef);

        const streamingVideoUrl1 = process.env.REACT_APP_MOVIES_SOLUTION1;
        const streamingVideoUrl2 = process.env.REACT_APP_MOVIES_SOLUTION2;
        const streamingVideoUrl3 = process.env.REACT_APP_MOVIES_SOLUTION3;
        // video要素を取得してストリーミングURLを設定
        const videoElement1 = document.getElementById('sol1video');
        const videoElement2 = document.getElementById('sol2video');
        const videoElement3 = document.getElementById('sol3video');
        videoElement1.src = streamingVideoUrl1;
        videoElement2.src = streamingVideoUrl2;
        videoElement3.src = streamingVideoUrl3;
    }, []);

    const toggleExpansionSol1 = () => {
        setActiveSol1(!activeSol1);
    };
    const toggleExpansionSol2 = () => {
        setActiveSol2(!activeSol2);
    };
    const toggleExpansionSol3 = () => {
        setActiveSol3(!activeSol3);
    };

    return (
        <div class="solution" ref={solRef} id="solution">
            <div class="sol-container">
                <div className={activeSol1 ? 'sol sol1' : 'sol sol-click'} onClick={toggleExpansionSol1}>
                    <div class="sol-head num01">
                        <div class="sol-num">
                            <p>01</p>
                        </div>
                        <h1>プログラミングスクール</h1>
                    </div>
                    <video id="sol1video" autoPlay loop muted playsInline loading="lazy"></video>
                    <div className={activeSol1 ? 'solpop popping' : 'solpop popped'}>
                        <div class="sol-desc">
                            <p>
                                私たちはIT人材の育成に力を入れています。子供向けのプログラミングスクールから大人向けのプログラミングスクールまで
                                展開しており、IT人材不足がささやかれる日本で優秀なIT人材を増やすために注力しております。子供向けプログラミングスクールでは
                                代表が子供のころから独学でプログラミングを学んでいた経験を活かし運営しております。大人向けプログラミングスクールでは
                                実際に現役フリーランスとして活動するエンジニアや元大企業のエンジニア中心に実践的なカリキュラムで運営しております。
                            </p>
                        </div>
                        <div class="sol-wrap">
                            <div class="solinner sol1-1">
                                <a href="https://school.K-Platinum.com/k-programming/">
                                    <h2>Kプログラミングスクール</h2>
                                    <img id="num01-img1" src="img/school1.png" border="0"></img>
                                </a>
                            </div>
                            <div class="solinner sol1-2">
                                <a href="https://school.K-Platinum.com/k-freelance/">
                                    <h2>Kフリー</h2>
                                    <img id="num01-img2" src="img/school2.png" border="0"></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={activeSol2 ? 'sol sol2' : 'sol sol-click'} onClick={toggleExpansionSol2}>
                    <div class="sol-head num02">
                        <div class="sol-num">
                            <p>02</p>
                        </div>
                        <h1>ITコンサルティング</h1>
                    </div>
                    <video id="sol2video" autoPlay loop muted playsInline loading="lazy"></video>{' '}
                    <div className={activeSol2 ? 'solpop popping' : 'solpop popped'}>
                        <div class="sol-desc">
                            <p>
                                私たちはITコンサル事業を展開しております。外資系の大手金融会社のクライアントを中心に、様々な企業に対してITコンサルティングを提供しております。
                                上流から下流までのトータルコーディネートが可能で、要件定義だけでなく、開発やマーケティング、UI/UX設計なども担うことが出来る高いITスキルを有しております。
                            </p>
                        </div>
                    </div>
                </div>
                <div className={activeSol3 ? 'sol sol3' : 'sol sol-click'} onClick={toggleExpansionSol3}>
                    <div class="sol-head num03">
                        <div class="sol-num">
                            <p>03</p>
                        </div>
                        <h1>独立支援事業</h1>
                    </div>
                    <video id="sol3video" autoPlay loop muted playsInline loading="lazy"></video>{' '}
                    <div className={activeSol3 ? 'solpop popping' : 'solpop popped'}>
                        <div class="sol-desc">
                            <p>
                                弊社は若いエンジニアの独立を支援しております。独立のためのノウハウの伝授やコーチング、事業計画書の書き方や案件の獲得方法についてアドバイスをしています。
                                案件獲得後の立ち回りなども徹底的にサポートします。エンジニア独立のためのカリキュラムを用意しております。
                                また、社会人エンジニアに対しても社内での評価を上げるためのコーチングをしております。社会人として他より優秀だと思えないと独立する気にはならないです。
                                私たちは自身のスキルをアップさせたい方のお手伝いをしたいと考えています。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
