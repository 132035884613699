import React from 'react';
import data from '../json/exlink.json';

export const Exlink = () => {
    return (
        <div className="exlink">
            <div className="ex-container">
                <div className="ex-field">
                    <a href={data.youtube} target="_blank">
                        <img src="img/youtube.png" />
                    </a>
                    <a href={data.instagram} target="_blank">
                        <img src="img/Instagram.png" />
                    </a>
                    <a href={data.twitter} target="_blank">
                        <img src="img/X.svg" />
                    </a>
                </div>
            </div>
        </div>
    );
};
