import React, { useEffect, useRef, useState } from 'react';
import Data from '../json/load-text.json';

// TextScramble class
class TextScramble {
    constructor(el) {
        this.el = el;
        this.chars = '!<>-_\\/[]{}—=+*^?#________';
        this.update = this.update.bind(this);
    }
    setText(newText) {
        const oldText = this.el.innerText;
        const length = Math.max(oldText.length, newText.length);
        const promise = new Promise((resolve) => (this.resolve = resolve));
        this.queue = [];
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || '';
            const to = newText[i] || '';
            const start = Math.floor(Math.random() * 30);
            const end = start + Math.floor(Math.random() * 30);
            this.queue.push({ from, to, start, end });
        }
        cancelAnimationFrame(this.frameRequest);
        this.frame = 0;
        this.update();
        return promise;
    }
    update() {
        let output = '';
        let complete = 0;
        for (let i = 0, n = this.queue.length; i < n; i++) {
            let { from, to, start, end, char } = this.queue[i];
            if (this.frame >= end) {
                complete++;
                output += to;
            } else if (this.frame >= start) {
                if (!char || Math.random() < 0.28) {
                    char = this.randomChar();
                    this.queue[i].char = char;
                }
                output += `<span class="dud">${char}</span>`;
            } else {
                output += from;
            }
        }

        if (output.includes(')') && this.frame >= this.queue.length - 1) {
            const index = output.indexOf(')');
            output = output.substring(0, index + 1) + '<br>' + '<br>' + output.substring(index + 1);
        }
        this.el.innerHTML = output;
        if (complete === this.queue.length) {
            this.resolve();
        } else {
            this.frameRequest = requestAnimationFrame(this.update);
            this.frame++;
        }
    }
    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)];
    }
}

// React component
export const Load = () => {
    const textRef = useRef(null);
    const japaneseTextRef = useRef(null);
    const [isFadeout, setIsFadeout] = useState(false);
    const [isZindex, setIsZindex] = useState(false);
    const [isJaText, setIsJaText] = useState(false);
    const [progress, setProgress] = useState(0);
    let isEndChar = false;

    useEffect(() => {
        const el = textRef.current;
        const japaneseEl = japaneseTextRef.current;
        const fx = new TextScramble(el);

        const phrases = Object.values(Data).flat(); // Flatten the array of phrases

        let counter = 0;
        let timer; // Timer to control the repetition
        let progressTimer; // Timer for progress bar

        const next = () => {
            if (counter < phrases.length) {
                    setIsJaText(false);
                    // For English phrases
                    fx.setText(phrases[counter]).then(() => {
                        timer = setTimeout(next, 1000);
                    });
            } else {
                // Display "K.Platinum" after all phrases
                setIsJaText(false);
                fx.setText('K.Platinum').then(() => {
                    isEndChar = true;
                    timer = setTimeout(() => {
                        setIsFadeout(true);
                        timer = setTimeout(() => {
                            setIsZindex(true);
                        }, 2000);
                    }, 2000);
                });
            }
            counter++;
        };

        next();
        // Start progress bar
        const startTime = Date.now();
        
        // Cleanup function
        return () => {
            clearTimeout(timer); // Clear the timer on component unmount
        };
    }, []);

    return (
        <div className={`loaded${isFadeout ? ' loaded-fadeout' : ''}${isZindex ? ' loaded-zindex' : ''}`} id="loader">
            <div className="load-animation">
                <span className="letter" ref={textRef}></span>
            </div>
        </div>
    );
};