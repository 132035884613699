import React from 'react';
import './App.css';
import { Hammenu_NotHome } from './components/Hammenu_NotHome';
import { Benefits } from './components/Benefits';
import { Exlink } from './components/Exlink';
import { Footer } from './components/Footer';

export const SpComponent_Benefits = () => {
    return (
        <div className="App">
            <div class="container">
                <Hammenu_NotHome />
                <body class="frame">
                    <Benefits />
                    <Exlink />
                    <Footer />
                </body>
            </div>
        </div>
    );
};
