import React, { useEffect } from 'react';
import './App.css';
import { Sidebar_NotHome } from './components/Sidebar_NotHome';
import { CompanyInfo } from './components/CompanyInfo';
import { Exlink } from './components/Exlink';
import { Footer } from './components/Footer';

export const PcComponent_CompanyInfo = () => {
    return (
        <div className="App">
            <div class="container">
                <body class="frame">
                    <Sidebar_NotHome />
                    <CompanyInfo />
                    <Exlink />
                    <Footer />
                </body>
            </div>
        </div>
    );
};
