import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

export const Benefits = () => {
    useEffect(() => {
        const streamingVideoUrl = process.env.REACT_APP_MOVIES_BENEFITS;
        // video要素を取得してストリーミングURLを設定
        const videoElement = document.getElementById('benefits-video');
        videoElement.src = streamingVideoUrl;
    }, []);

    return (
        <div class="benefits" id="Benefits">
            <div class="breadcrumbs">
                <HashLink to="/#screentop" className="">
                    home
                </HashLink>
                <span>　>　</span>
                <span>Benefits</span>
            </div>
            <div class="benefits-area">
                <h1>Benefits</h1>
                <p>
                    ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。
                </p>
                <div class="benefits-contents">
                    <div class="benefits-content">
                        <div class="benefits-title">Private Bar</div>
                        <div class="benefits-bar-wrap">
                            <HashLink to="https://www.instagram.com/binksterrace/" className="benefits-img-wrap">
                                <div class="benefits-name">Bink's TERRACE</div>
                                <div class="benefits-img">
                                    <img src="img/benefits/Bink'sTerrace.jpg"></img>
                                </div>
                            </HashLink>
                            <HashLink to="https://www.instagram.com/binks_shibuya/" className="benefits-img-wrap">
                                <div class="benefits-name">IZAKAYA BAR Bink's</div>
                                <div class="benefits-img">
                                    <img src="img/benefits/IzakayaBarBink's.jpg"></img>
                                </div>
                            </HashLink>
                        </div>
                    </div>
                    <div class="benefits-content">
                        <div class="benefits-title">Memebership Salon</div>
                        <div className="benefits-img-wrap">
                            <div class="benefits-name">会員制レストラン</div>
                            <div class="benefits-img">
                                <img src="img/benefits/Restaurant.jpg"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="benefits-video">
                    <video id="benefits-video" autoPlay loop muted playsInline loading="lazy"></video>
                </div>
            </div>
        </div>
    );
};
