import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';

export const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="footer-field">
                    <HashLink to="/">
                        <a>K.Platinum</a>
                    </HashLink>
                    <div className="right-item">
                        <HashLink to="/CompanyInfo/#CompanyInfo">会社概要</HashLink>
                        <HashLink to="/PrivacyPolicy/#PrivacyPolicy">プライバシーポリシー</HashLink>
                    </div>
                </div>
            </div>
            <div className="flowting-icon">
                <span>Scroll</span>
            </div>
        </div>
    );
};
