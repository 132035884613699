import React, { useRef, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { mediaQuery, useMediaQuery } from './UseMediaQuery';
import memberJson from '../json/member.json';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export const Member = () => {
    const isSp = useMediaQuery(mediaQuery.sp);
    const isMemberSp = useMediaQuery(mediaQuery.memberSp);
    gsap.registerPlugin(ScrollTrigger);

    const memberNodes = memberJson.member.slice(0, 4).map((member, id) => (
        <div className="member-container" key={id}>
            <img loading="lazy" className="member-img" src={member.img} alt={`Member ${id}`} />
            <div className="member-info">
                <div className="member-post-container">
                    {member.post.map((post, postId) => (
                        <div className="member-post" key={postId}>
                            <a>{post}</a>
                        </div>
                    ))}
                </div>
                <div className="member-name">
                    <div className="name-jp">{member['name-jp']}</div>
                    <div className="name-en">{member['name-en']}</div>
                </div>
                <div className="member-desc">{member.description}</div>
            </div>
        </div>
    ));

    const memberRef = useRef();
    const fieldRef = useRef();
    const scrollRef = useRef();

    let ctx;
    useEffect(() => {
        ctx && ctx.revert();
        ctx = gsap.context(() => {
            const wrapper = document.querySelector('.member');
            const slides = gsap.utils.toArray('.member-container');
            let wrapperWidth = wrapper.offsetWidth + 2500;
            if (isMemberSp === true) {
                // 横スクロールアニメーションの設定(SP)
                gsap.to(slides, {
                    xPercent: -161 * (slides.length - 1), // -X軸方向に移動
                    ease: 'none', // アニメーションのイージング(noneは定速)
                    scrollTrigger: {
                        trigger: wrapper, // アニメーション開始のトリガー要素
                        //duration: 2,
                        pin: true, // 要素を固定
                        scrub: 0.1, // スクロール量に合わせてアニメーション
                        end: `+=${wrapperWidth}`, // アニメーションが終わる位置
                        // markers:true,
                    },
                });
            } else if (isSp === true) {
                // 横スクロールアニメーションの設定(Tab)
                gsap.to(slides, {
                    xPercent: -115 * (slides.length - 1), // -X軸方向に移動
                    ease: 'none', // アニメーションのイージング(noneは定速)
                    scrollTrigger: {
                        trigger: wrapper, // アニメーション開始のトリガー要素
                        //duration: 2,
                        pin: true, // 要素を固定
                        scrub: 0.1, // スクロール量に合わせてアニメーション
                        end: `+=${wrapperWidth}`, // アニメーションが終わる位置
                        // markers:true,
                    },
                });
            } else {
                // 横スクロールアニメーションの設定(PC)
                gsap.to(slides, {
                    xPercent: -100 * (slides.length - 1), // -X軸方向に移動
                    ease: 'none', // アニメーションのイージング(noneは定速)
                    scrollTrigger: {
                        trigger: wrapper, // アニメーション開始のトリガー要素
                        pin: true, // 要素を固定
                        scrub: 1, // スクロール量に合わせてアニメーション
                        end: `+=${wrapperWidth}`, // アニメーションが終わる位置
                        // markers:true,
                    },
                });
            }
        }, memberRef);
    }, []);

    return (
        <div className="member" ref={memberRef} id="member">
            <h1 className="title">Member</h1>
            <div className="member-field" ref={fieldRef}>
                <div className="scrollsection" ref={scrollRef}>
                    {memberNodes}
                    <div className="member-container member-link">
                        <HashLink to="/Member/#MemberDetail" class="member-btn">
                            <span class="member-btn-text">VIEW MORE</span>
                        </HashLink>
                    </div>
                </div>
            </div>
        </div>
    );
};
