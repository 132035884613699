import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';

export const Contact = () => {
    const [inputValues, setInputValues] = useState({});
    const [focusStates, setFocusStates] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        phone: '',
        email: '',
        message: '',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(false); // 送信中の状態

    //処理追加
    // フォームのバリデーションを行う関数
    const validateForm = () => {
        const errors = [];

        // 各フィールドの制約を確認し、問題があればエラーを追加
        if (!formData.name) {
            errors.push('name');
        }
        if (!formData.company) {
            errors.push('company');
        }
        if (!formData.email) {
            errors.push('email');
        }
        if (!isValidEmail(formData.email)) {
            errors.push('emailformaterror');
        }
        if (!formData.message) {
            errors.push('message');
        }
        if (!formData.phone) {
            errors.push('phone');
        }
        if (isNaN(formData.phone)) {
            errors.push('phoneformaterror');
        }

        return errors;
    };
    // メールのフォーマットが正しいかどうかを確認する関数
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSending(true); // 送信中の状態に変更

        const templateParams = {
            name: formData.name,
            company: formData.company,
            phone: formData.phone,
            email: formData.email,
            message: formData.message,
        };

        emailjs
            .send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
            .then((response) => {
                setIsSending(false); // 送信完了後、状態を元に戻す
                alert('送信が完了しました。担当者より折り返しご連絡いたしますので少々お待ちください。');
                closeModal(); // モーダルを閉じる
            })
            .catch((error) => {
                setIsSending(false); // エラーが発生した場合も状態を元に戻す
                alert('エラーが発生しました。申し訳ありませんが、画面更新の上再度お試しください。');
                closeModal(); // エラーの場合もモーダルを閉じる
            });
    };

    const handleConfirmation = (e) => {
        e.preventDefault(); // デフォルトのフォーム送信を防止
        //変更
        //setIsModalOpen(true);
        // 追加：バリデーションを行う
        const validationErrors = validateForm();
        //エラー箇所の枠を赤にする。
        handleValidationErrors(validationErrors);

        //エラー文作成
        let errorMsg = '';

        validationErrors.forEach(function (error) {
            switch (error) {
                case "name":
                    if (!errorMsg.includes('未入力の項目があります。\n')) {
                        errorMsg += '未入力の項目があります。\n';
                    }
                    break;
                case "company":
                    if (!errorMsg.includes('未入力の項目があります。\n')) {
                        errorMsg += '未入力の項目があります。\n';
                    }
                    break;
                case "email":
                    if (!errorMsg.includes('未入力の項目があります。\n')) {
                        errorMsg += '未入力の項目があります。\n';
                    }
                    break;
                case "message":
                    if (!errorMsg.includes('未入力の項目があります。\n')) {
                        errorMsg += '未入力の項目があります。\n';
                    }
                    break;
                case "phone":
                    if (!errorMsg.includes('未入力の項目があります。\n')) {
                        errorMsg += '未入力の項目があります。\n';
                    }
                    break;
                case "emailformaterror":
                    if (!validationErrors.includes("email")) {
                        errorMsg += "Email:正しいメールアドレスではありません。\n";
                    }
                    break;
                case "phoneformaterror":
                    if (!validationErrors.includes("phone")) {
                        errorMsg += "Phone:ハイフンなしの数字で入力ください。\n";
                    }
                    break;
                default:
                    break;
            }
        });
        if (errorMsg !== "") {
            // エラーがある場合はエラーメッセージを表示
            alert(errorMsg);
            errorMsg = "";
        } else {
            // エラーがない場合はモーダルを開く
            setIsModalOpen(true);
        }
    };

    const [errorStates, setErrorStates] = useState({
        inputFieldName: false,
        inputFieldCompany: false,
        inputFieldPhone: false,
        inputFieldEmail: false,
        inputFieldMessage: false,
    });
    // スタイルを追加する部分
    const inputBoxStyle = (inputId) => {
        return {
            border: `${errorStates[inputId] ? '1px solid red' : ' '}`,
        };
    };
    // 追加：エラーがある項目のinputboxを赤くする関数
    const handleValidationErrors = (errors) => {
        const inputIdsWithError = errors.map((error) => `inputField${capitalizeFirstLetter(error)}`);
        setFocusStates((prevFocusStates) => {
            const updatedFocusStates = { ...prevFocusStates };
            const updatedErrorStates = { ...errorStates };
            updatedErrorStates['inputFieldName'] = false;
            updatedErrorStates['inputFieldCompany'] = false;
            updatedErrorStates['inputFieldPhone'] = false;
            updatedErrorStates['inputFieldEmail'] = false;
            updatedErrorStates['inputFieldMessage'] = false;
            inputIdsWithError.forEach((inputId) => {
                updatedFocusStates[inputId] = true;
                updatedErrorStates[inputId] = true;
            });
            setErrorStates(updatedErrorStates);
            return updatedFocusStates;
        });
    };

    // 追加：文字列の先頭を大文字にする関数
    const capitalizeFirstLetter = (str) => {
        let error = str.charAt(0).toUpperCase() + str.slice(1);
        if (str === "emailformaterror") {
            error = "Email";
        } else if (str === "phoneformaterror") {
            error = "Phone";
        }
        return error;
    };

    // 追加：バリデーションが成功した場合にエラーフラグをクリアする
    const clearError = () => {
        setErrorStates({
            inputName: false,
            inputCompany: false,
            inputPhone: false,
            inputEmail: false,
            inputMessage: false,
        });
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (inputId, value) => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [inputId]: value,
        }));

        if (inputId === 'inputName') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                name: value,
            }));
        }
        if (inputId === 'inputCompany') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                company: value,
            }));
        }
        if (inputId === 'inputPhone') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone: value,
            }));
        }
        if (inputId === 'inputEmail') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email: value,
            }));
        }
        if (inputId === 'inputMessage') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                message: value,
            }));
        }
    };

    const handleInputFocus = (inputId) => {
        setFocusStates((prevFocusStates) => ({
            ...prevFocusStates,
            [inputId]: true,
        }));
    };

    const handleInputBlur = (inputId) => {
        if (!inputValues[inputId]) {
            setFocusStates((prevFocusStates) => ({
                ...prevFocusStates,
                [inputId]: false,
            }));
        }
    };

    const [sendingText, setSendingText] = useState('SENDING');

    //モーダルオープンしたらfooterにかぶるので、footerのz-indexを下げる。
    useEffect(() => {
        const footerElement = document.querySelector('.footer');
        if (footerElement) {
            footerElement.style.zIndex = isModalOpen ? '1000' : '1002';
        }

        const flowtingIconElement = document.querySelector('.flowting-icon');
        if (flowtingIconElement) {
            flowtingIconElement.style.display = isModalOpen ? 'none' : 'block';
        }

        const bodyElement = document.body;

        // モーダルが開いているときはスクロールを禁止
        if (isModalOpen) {
            bodyElement.style.overflow = 'hidden';
        } else {
            // モーダルが閉じているときは通常通りにスクロールを許可
            bodyElement.style.overflow = 'scroll';
        }
        // (Optional) クリーンアップ関数
        return () => {
            bodyElement.style.overflow = 'scroll';
        };
    }, [isModalOpen]);

    useEffect(() => {
        let intervalId;

        if (isSending) {
            // 0.5秒ごとに文字列を切り替える
            intervalId = setInterval(() => {
                setSendingText((prevText) => (prevText === 'SENDING' ? 'SENDING.' : 'SENDING'));
            }, 500);
        } else {
            // 送信が完了したらクリア
            setSendingText('SENDING');
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId); // コンポーネントがアンマウントされるときにインターバルをクリア
    }, [isSending]);
    return (
        <div className="contact" id="contact">
            <h1 className="title">Contact</h1>
            <form className="contact-form" onSubmit={handleConfirmation}>
                <div className="contactform-area">
                    <div className="contactforms">
                        <div className={`contact-input-box ${focusStates.inputName ? 'focused' : ''}`} style={inputBoxStyle('inputFieldName')}>
                            <label className={`${focusStates.inputName ? 'focused' : ''}`} htmlFor="inputFieldName">
                                Name
                            </label>
                            <input
                                id="inputFieldName"
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={(e) => handleInputChange('inputName', e.target.value)}
                                onFocus={() => handleInputFocus('inputName')}
                                onBlur={() => handleInputBlur('inputName')}
                            />
                        </div>
                        <div className={`contact-input-box ${focusStates.inputCompany ? 'focused' : ''}`} style={inputBoxStyle('inputFieldCompany')}>
                            <label className={`${focusStates.inputCompany ? 'focused' : ''}`} htmlFor="inputFieldCompany">
                                Company
                            </label>
                            <input
                                id="inputFieldCompany"
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={(e) => handleInputChange('inputCompany', e.target.value)}
                                onFocus={() => handleInputFocus('inputCompany')}
                                onBlur={() => handleInputBlur('inputCompany')}
                            />
                        </div>
                        <div className={`contact-input-box ${focusStates.inputPhone ? 'focused' : ''}`} style={inputBoxStyle('inputFieldPhone')}>
                            <label className={`${focusStates.inputPhone ? 'focused' : ''}`} htmlFor="inputFieldPhone">
                                Phone
                            </label>
                            <input
                                id="inputFieldPhone"
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={(e) => handleInputChange('inputPhone', e.target.value)}
                                onFocus={() => handleInputFocus('inputPhone')}
                                onBlur={() => handleInputBlur('inputPhone')}
                            />
                        </div>
                        <div className={`contact-input-box ${focusStates.inputEmail ? 'focused' : ''}`} style={inputBoxStyle('inputFieldEmail')}>
                            <label className={`${focusStates.inputEmail ? 'focused' : ''}`} htmlFor="inputFieldEmail">
                                Email
                            </label>
                            <input
                                id="inputFieldEmail"
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={(e) => handleInputChange('inputEmail', e.target.value)}
                                onFocus={() => handleInputFocus('inputEmail')}
                                onBlur={() => handleInputBlur('inputEmail')}
                            />
                        </div>
                    </div>
                    <div className="contactforms contactforms-textarea">
                        <div className={`contact-textarea-box ${focusStates.inputMessage ? 'focused' : ''}`} style={inputBoxStyle('inputFieldMessage')}>
                            <label className={`${focusStates.inputMessage ? 'focused' : ''}`} htmlFor="inputFieldMessage">
                                Message
                            </label>
                            <textarea
                                id="inputFieldMessage"
                                type="text"
                                name="message"
                                value={formData.message}
                                onChange={(e) => handleInputChange('inputMessage', e.target.value)}
                                onFocus={() => handleInputFocus('inputMessage')}
                                onBlur={() => handleInputBlur('inputMessage')}
                            />
                        </div>
                    </div>
                </div>
                <button className="send" type="submit" disabled={isSending}>
                    <a>{isSending ? '送信中...' : 'CONFIRMATION'}</a>
                </button>
            </form>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-inner">
                            <div className="close-button" onClick={closeModal}>
                                ×
                            </div>
                            <div className="modal-title">Confirmation</div>
                            <div className="modal-content-wrap">
                                <div className="modal-content">
                                    <div className="modal-content-name">Name</div>
                                    <div className="modal-content-text">{formData.name}</div>
                                </div>
                                <div className="modal-content">
                                    <div className="modal-content-name">Company</div>
                                    <div className="modal-content-text">{formData.company}</div>
                                </div>
                                <div className="modal-content">
                                    <div className="modal-content-name">Phone</div>
                                    <div className="modal-content-text">{formData.phone}</div>
                                </div>
                                <div className="modal-content">
                                    <div className="modal-content-name">Email</div>
                                    <div className="modal-content-text">{formData.email}</div>
                                </div>
                                <div className="modal-content">
                                    <div className="modal-content-name">Message</div>
                                    <div className="modal-content-text">{formData.message}</div>
                                </div>
                            </div>
                        </div>
                        <button className="modal-send" onClick={sendEmail} disabled={isSending}>
                            <a>{isSending ? sendingText : 'SEND EMAIL'}</a>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
