// src/hooks/useIntersectionObserver.js

import { useEffect, useState } from 'react';

const useIntersectionObserver = (targetRef, videoRef) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        if (videoRef.current && videoRef.current.paused) {
                            videoRef.current.play().catch((error) => {
                                console.error('Error playing video:', error);
                            });
                        }
                        videoRef.current.style.display = 'block';
                    } else {
                        setIsVisible(false);
                        if (videoRef.current && !videoRef.current.paused) {
                            videoRef.current.pause();
                        }
                        videoRef.current.style.display = 'none';
                    }
                });
            },
            { threshold: 0.01 }
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [targetRef, videoRef]);

    return isVisible;
};

export default useIntersectionObserver;
