import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import './App.css';
import { Load } from './components/Load';
import { Sidebar } from './components/Sidebar';
import { Top } from './components/Top';
import { BackVideo } from './components/BackVideo';
import { Solution } from './components/Solution';
import { Member } from './components/Member';
import { Recruit } from './components/Recruit';
import { Contact } from './components/Contact';
import { Exlink } from './components/Exlink';
import { Footer } from './components/Footer';

export const PcComponent_home = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        ScrollTrigger.create({
            // markers: true,
            trigger: '#top',
            start: 'top center',
            end: '5% top',
            toggleClass: {
                targets: '#side-top',
                className: 'top-is-active',
            },
        });
        ScrollTrigger.create({
            // markers: true,
            trigger: '#top',
            start: '5% top',
            end: '17% top',
            toggleClass: {
                targets: '#side-policy',
                className: 'policy-is-active',
            },
        });
        ScrollTrigger.create({
            // markers: true,
            trigger: '#solution',
            start: 'top center',
            end: 'bottom top',
            toggleClass: {
                targets: '#side-sol',
                className: 'sol-is-active',
            },
        });
        ScrollTrigger.create({
            // markers: true,
            trigger: '#solution',
            start: 'bottom bottom',
            toggleClass: {
                targets: '#BackVideo_video',
                className: 'BackVideo-is-visible',
            },
        });
        ScrollTrigger.create({
            //markers: true,
            trigger: '#member',
            start: 'top center',
            end: 'bottom -90%',
            toggleClass: {
                targets: '#side-member',
                className: 'member-is-active',
            },
        });
        ScrollTrigger.create({
            // markers: true,
            trigger: '#recruit',
            start: 'top center',
            end: 'bottom center',
            toggleClass: {
                targets: '#side-recruit',
                className: 'recruit-is-active',
            },
        });
        ScrollTrigger.create({
            // markers: true,
            trigger: '#contact',
            start: 'top center',
            end: 'bottom center',
            toggleClass: {
                targets: '#side-contact',
                className: 'contact-is-active',
            },
        });
    }, []);

    const [showLoad, setShowLoad] = useState(false);
    const [cookies, setCookie] = useCookies(['loadDisabledTimestamp']); // Change the cookie name

    useEffect(() => {
        // 現在時刻を取得
        const currentTimestamp = new Date().getTime();

        // URLパラメータを取得
        const urlParams = new URLSearchParams(window.location.search);
        const testParam = urlParams.get('load');

        if (testParam === 'true') {
            setShowLoad(true);
        } else if (cookies.loadDisabledTimestamp === undefined) {
            setCookie('loadDisabledTimestamp', currentTimestamp.toString(), { maxAge: 24 * 60 * 60 });
            setShowLoad(true);
        } else {
            const loadDisabledTimestamp = parseInt(cookies.loadDisabledTimestamp, 10);
            const elapsedSeconds = (currentTimestamp - loadDisabledTimestamp) / 1000;

            // 24時間経ってたらtrue
            if (elapsedSeconds < 24 * 60 * 60) {
                setShowLoad(false);
            } else {
                setCookie('loadDisabledTimestamp', currentTimestamp.toString(), { maxAge: 24 * 60 * 60 });
                setShowLoad(true);
            }
        }
    }, []);

    return (
        <div className="App">
            <div className="container">
                <header></header>
                <body className="frame">
                    {showLoad && <Load showLoad={showLoad} />}
                    <Sidebar />
                    <div className="policyAnchor" id="policy"></div>
                    <Top id="top" />
                    <Solution id="solution" />
                    <BackVideo />
                    <Member />
                    <Recruit id="recruit" />
                    <Contact id="contact" />
                    <Exlink />
                    <Footer />
                </body>
            </div>
        </div>
    );
};
