import React from 'react';
import { HashLink } from 'react-router-hash-link';

export const RecruitDetail = () => {
    return (
        <div class="recruit-detail" id="Recruit">
            <div class="breadcrumbs">
                <HashLink to="/#screentop" className="">
                    home
                </HashLink>
                <span>　>　</span>
                <span>Recruit</span>
            </div>
            <h1>Recruit</h1>
            <div class="recruit-detail-area">
                <div class="recruit-detail-area-wrap">
                    <div>現在、 以下の職種にてメンバーを募集しています。</div>
                    <h2 class="recruit-detail-area-h2">・IT Consultant</h2>
                    <h2 class="recruit-detail-area-h2">・System Engineer</h2>
                    <h2 class="recruit-detail-area-h2">・Sales</h2>
                    <div class="recruit-detail-btn-wrap">
                        <HashLink to="https://docs.google.com/forms/d/e/1FAIpQLSdHZULZI_xyUivAb71KTW7w42Pm6SCbdVWTg1xOCQvfQ9aELA/viewform?usp=sf_link" class="member-btn">
                            <span class="member-btn-text">採用フォームはこちら</span>
                        </HashLink>
                    </div>
                </div>
            </div>
        </div>
    );
};
