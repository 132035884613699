import React from 'react';
import './App.css';
import { Hammenu_NotHome } from './components/Hammenu_NotHome';
import { MemberDetail } from './components/MemberDetail';
import { Exlink } from './components/Exlink';
import { Footer } from './components/Footer';

export const SpComponent_MemberDetail = () => {
    return (
        <div className="App">
            <div class="container">
                <Hammenu_NotHome />
                <body class="frame">
                    <MemberDetail />
                    <Exlink />
                    <Footer />
                </body>
            </div>
        </div>
    );
};
