import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './App.css';
import { Load } from './components/Load';
import { TopSp } from './components/TopSp';
import { PolicySp } from './components/PolicySp';
import { BackVideo } from './components/BackVideo';
import SolutionSp from './components/SolutionSp';
import { Member } from './components/Member';
import { RecruitSp } from './components/RecruitSp';
import { Contact } from './components/Contact';
import { Exlink } from './components/Exlink';
import { Footer } from './components/Footer';
import { Hammenu } from './components/Hammenu';

export const SpComponent_home = () => {
    const [showLoad, setShowLoad] = useState(false);
    const [cookies, setCookie] = useCookies(['loadDisabledTimestamp']); // Change the cookie name

    useEffect(() => {
        // 現在時刻を取得
        const currentTimestamp = new Date().getTime();

        // URLパラメータを取得
        const urlParams = new URLSearchParams(window.location.search);
        const testParam = urlParams.get('load');

        if (testParam === 'true') {
            setShowLoad(true);
        } else if (cookies.loadDisabledTimestamp === undefined) {
            setCookie('loadDisabledTimestamp', currentTimestamp.toString(), { maxAge: 24 * 60 * 60 });
            setShowLoad(true);
        } else {
            const loadDisabledTimestamp = parseInt(cookies.loadDisabledTimestamp, 10);
            const elapsedSeconds = (currentTimestamp - loadDisabledTimestamp) / 1000;

            // 24時間に修正
            if (elapsedSeconds < 24 * 60 * 60) {
                setShowLoad(false);
            } else {
                setCookie('loadDisabledTimestamp', currentTimestamp.toString(), { maxAge: 24 * 60 * 60 });
                setShowLoad(true);
            }
        }
    }, []);

    return (
        <div className="App">
            <div className="container">
                <Hammenu />
                {showLoad && <Load showLoad={showLoad} />}
                <div className="frame">
                    <TopSp id="top" />
                    <PolicySp />
                    <SolutionSp id="solution" />
                    <BackVideo />
                    <Member />
                    <RecruitSp id="recruit" />
                    <Contact id="contact" />
                    <Exlink />
                    <Footer />
                </div>
            </div>
        </div>
    );
};
