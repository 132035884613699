import React from 'react';
import { HashLink } from 'react-router-hash-link';
import memberJson from '../json/member.json';

export const MemberDetail = () => {
    const memberDetailNodes = memberJson.member.map((member, id) => (
        <div className={`member-detail-container ${id === memberJson.member.length - 1 ? 'last-member-style' : ''}`} key={id}>
            <div className="member-detail-img">
                <img loading="lazy" src={member.img} alt={`Member ${id}`} />
            </div>
            <div className="member-detail-info">
                <div className="member-detail-post-container">
                    {member.post.map((post, postId) => (
                        <div className="member-post text-transparent" key={postId}>
                            <a>{post}</a>
                        </div>
                    ))}
                </div>
                <div className="member-detail-name">
                    <div className="member-detail-name-jp">{member['name-jp']}</div>
                    <div className="member-detail-name-en">{member['name-en']}</div>
                </div>
                <div className="member-detail-desc">{member.description}</div>
            </div>
        </div>
    ));

    return (
        <div class="member-detail" id="MemberDetail">
            <div class="breadcrumbs">
                <HashLink to="/#screentop" className="">
                    home
                </HashLink>
                <span>　>　</span>
                <span>Member</span>
            </div>
            <h1>Member</h1>
            <div class="member-detail-area">
                <div class="member-detail-area-wrap">{memberDetailNodes}</div>
            </div>
        </div>
    );
};
