import React, { useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { gsap } from 'gsap';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

export const RecruitSp = () => {
    const conRef = useRef();
    const targetRef = useRef(null);
    const videoRef = useRef();

    useIntersectionObserver(targetRef, videoRef);

    const streamingVideoUrl = process.env.REACT_APP_MOVIES_RECRUIT_SP;
    useEffect(() => {
        // Set the video source when it's available
        if (videoRef.current) {
            videoRef.current.src = streamingVideoUrl;
        }
    }, [streamingVideoUrl]);

    useEffect(() => {
        let ctx;
        const initGsap = () => {
            ctx && ctx.revert();
            ctx = gsap.context(() => {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: conRef.current,
                        start: 'top top',
                        end: '+=200', // アニメーションが終了した後、さらに200pxスクロールされたらスクロールを再開
                        invalidateOnRefresh: true,
                        pin: true,
                    },
                });
            }, conRef);
        };

        initGsap(); // 初回の gsap 初期化

        window.addEventListener('load', initGsap); // ページの読み込みが完了したら実行

        return () => {
            // クリーンアップ処理
            window.removeEventListener('load', initGsap);
            ctx && ctx.revert();
        };
    }, []);

    return (
        <div className="recruit" ref={conRef} id="recruit">
            <div className="text" ref={targetRef}>
                <div className="recruit-head head1">Accelerate</div>
                <div className="recruit-head head2-1">your</div>
                <div className="recruit-head head2-2">thinking</div>
                <p>
                    私たちと一緒に働いてくれる
                    <wbr />
                    メンバーを募集しております。
                    <wbr />
                    やる気がある、日本を変えたい、
                    <wbr />
                    楽しく働きたいというメンバーを
                    <wbr />
                    募集しています。
                    <wbr />
                    詳細は下記リンクをクリック
                    <wbr />
                    してください
                </p>
                <HashLink to="/Recruit/#Recruit" className="button-recruit">
                    採用ページはこちら
                </HashLink>
            </div>
            <div className="filter"></div>
            <video ref={videoRef}  id="recruitvideo" autoPlay loop muted playsInline loading="lazy"></video>
        </div>
    );
};
