import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { isMobile } from 'react-device-detect';

export const Hammenu = () => {
    const [activeMenu, setActiveMenu] = useState(true);

    const toggleMenu = () => {
        setActiveMenu(!activeMenu);
    };
    const imageUrl = isMobile
        ? process.env.REACT_APP_MOVIES_LOGO_SP
        : process.env.REACT_APP_MOVIES_LOGO;
    const logo = {
        backgroundImage: `url(${imageUrl})`,
    };

    return (
        <div className="han-menu-wrap">
            <nav>
                <a href="/">
                    <div className="logo">
                        <h1 style={logo}>K</h1>
                    </div>
                </a>
                <div className={activeMenu ? 'ham-menu' : 'ham-menu active'} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </nav>
            <div className={activeMenu ? 'menusp' : 'menusp active'}>
                <ul>
                    <li>
                        <AnchorLink href="#screentop" id="side-policy">
                            Top
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#policy" id="side-policy">
                            Policy
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#solution" offset="-1" id="side-sol">
                            Solution
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#member" id="side-member">
                            Member
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#recruit" id="side-recruit">
                            Recruit
                        </AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#contact" id="side-contact">
                            Contact
                        </AnchorLink>
                    </li>
                </ul>
            </div>
        </div>
    );
};
